.Hands {

}

.hand-2 {
    top: -70px;
}

.hand-3, .hand-4 {
    top: 0;
}

.hand-5, .hand-6 {
    top: 70px;
}

.point {
    align-content: center;
    font-size: medium;
    font-weight: bold;
}