@media screen and (max-width: 768px) {
    .Messages {
        max-height: 200px;
    }
}

@media screen and (min-width: 768px) {
    .Messages {
        max-height: 429px;
    }
}
