.Table {
    height: 152px;
    position: relative;
}

.table-2, .table-3 {
    top: 250px;
}

.table-4 {
    top: 350px;
}

.table-5 {
    top: 450px;
}

.table-6 {
    top: 550px;
}