.players-2, .players-3 {
    height: 300px;
}

.players-4 {
    height: 400px;
}

.players-5 {
    height: 500px;
}

.players-6 {
    height: 600px;
}

.player {
    position: absolute;
}

.player-1-2 {
    top: 0;
    left: 400px;
}

.player-2-2 {
    top: 0;
    left: 100px;
}

.player-1-3 {
    top: 100px;
    left: 400px;
}

.player-2-3 {
    top: 0;
    left: 200px;
}

.player-3-3 {
    top: 100px;
    left: 0;
}

.player-1-4 {
    top: 200px;
    left: 200px;
}

.player-2-4 {
    top: 100px;
    left: 400px;
}

.player-3-4 {
    top: 0;
    left: 200px;
}

.player-4-4 {
    top: 100px;
    left: 0;
}

.player-1-5 {
    top: 200px;
    left: 400px;
}

.player-2-5 {
    top: 0;
    left: 400px;
}

.player-3-5 {
    top: 200px;
    left: 0;
}

.player-4-5 {
    top: 200px;
    left: 0;
}

.player-1-6 {
    top: 300px;
    left: 400px;
}

.player-2-6 {
    top: 100px;
    left: 400px;
}

.player-3-6 {
    top: 0;
    left: 200px;
}

.player-4-6 {
    top: 100px;
    left: 0;
}

.player-5-6 {
    top: 300px;
    left: 0;
}